<template>
    <div class="location">
        <transition name="fade">
            <div key="index" class="search-btn" @click="iconShow = false" v-if="iconShow"><van-icon name="search" /></div>
            <div class="search-box" v-else>
                <van-search v-model="key" @input="watchKey" placeholder="请输入关键词检索设备" />
                <div v-if="showResult" :class="valueData.length >0 ? 'box-inner global-shadow' :'box-inner'">
                    <van-cell v-for="item in valueData" :key="item.id" :title="item.devName" @click="selectDev(item)">
                        <span :class="item.devStatus=='online'? 'online' : 'offline'" v-text="item.devStatus=='online'? '在线' : '离线'"></span>
                    </van-cell>
                </div>
            </div>
        </transition>
        <div id="container"></div> 
        <tabBar />
    </div>
</template>

<script>
import tabBar from '@/components/itgs/tabBar.vue'
import iconMark from '@/assets/itgs/icon-mark.png'
import cMark from '@/assets/itgs/720c-mark.png'
export default {
    name:'Location',
    components:{
        tabBar
    },
    data(){
        return{
            iconShow:true,
            showResult:false,
            allDev:[],
            valueData:[],
            key:'',
            map:'',//声明地图实例
            iconMark:iconMark,
            cMark:cMark
        }
    },
    mounted(){
        const projectCode = window.sessionStorage.getItem('projectCode');
        let tabSize = document.getElementsByClassName('van-tabbar')[0].scrollHeight; // 获取tab栏的高度
        document.getElementById('container').style.height = (window.screen.height - tabSize )+'px';
        this.map = new AMap.Map('container',{
            mapStyle: 'amap://styles/fresh', //设置地图的显示样式
            zooms:[4,19], //设置缩放级别范围 4-19 级
        });
        AMap.plugin(['AMap.ToolBar','AMap.Scale'],()=>{//异步同时加载多个插件
            var toolbar = new AMap.ToolBar();
            var scale = new AMap.Scale();
            this.map.addControl(toolbar);
            this.map.addControl(scale);
        });
        this.$api.ITGS.getProjectDev({
            projectCode:projectCode
        }).then((data) => {
            data.forEach( e => {

                const gps = [e.lon,e.lat]
                if(e.lon !== 0 || e.lat !== 0){
                    this.gpsTransformGaoDe(gps).then( d => {
                        e.lon = d.lng;
                        e.lat = d.lat;
                    })
                }
            })
            
            // 异步绘制-防止高德异步函数导致的加载失败问题
            setTimeout(()=>{
                this.allDev = data;
                this.updateMapMarker(data);
            },1500)
        })   
        // this.map.setFitView();//自动设置缩放比例，将全部mark点显示在地图控件中
        //点击地图，隐藏关闭搜索框
        this.map.on('click',() => {
            this.showResult = false;
            this.key = '';
            this.valueData = [];
            this.iconShow = true;
        })
        // //实时路况图层
        // var trafficLayer = new AMap.TileLayer.Traffic({
        //     zIndex: 10
        // });
        // map.add(trafficLayer);//添加图层到地图
    },
    destroyed(){
        this.map.destroy();   //销毁地图实例，防止跳转路由导致的bug报错提醒
    },
    methods:{
        // gps 转 高德坐标系
        gpsTransformGaoDe(gps){
            return new Promise( resolve => {
                new AMap.convertFrom(gps, 'gps', (status, result) => {
                    if (result.info === 'ok') {
                        resolve(result.locations[0])
                    }
                })
            })
        },
        updateMapMarker(data){
            // ------------------------海量点标记-----------------------
            let markArr = [];
            const devIcon ={
                size: [25, 34],
                image: iconMark,
            };
            const cIcon ={
                size: [25, 54],
                image: cMark,
            };
            for(let i=0;i<data.length;i++){
                if(data[i].lon){
                    var marker = new AMap.LabelMarker({
                        name: '海量点标记', // 此属性非绘制文字内容，仅最为标识使用
                        position: [data[i].lon,data[i].lat],
                        icon: data[i].devType == 'EV4101' ? cIcon : devIcon,
                        text: {
                            content: data[i].devName,
                            direction: 'right',
                            offset: [0, 0],
                            style: {
                                fontSize: 12.5,
                                fillColor: data[i].devStatusData =='1' ? '#0957ff' : '#443535',
                                strokeColor: '#fff',
                                strokeWidth: 2,
                            }
                        },
                    });
                    marker.on('click', (e) => {
                        this.$router.push({path:'/itgs/detail',query:{devCode:data[i].devCode}})
                    });
                    markArr.push(marker);
                }
            }
            this.map.setFitView(markArr[0],false,[140,10,140,10],17);//自动设置缩放比例，将全部mark点显示在地图控件中
            var labelsLayer = new AMap.LabelsLayer({
                collision: true,
            });
            labelsLayer.add(markArr);
            this.map.add(labelsLayer);
        },
        // 监听搜索结果
        watchKey(e){
            this.valueData = this.allDev.filter(v => v.devName.toLowerCase().includes(e.toLowerCase()))   //不区分大小写
            this.valueData ? this.showResult = true : this.showResult = false;
        },
        // 点击搜索结果，视野以该设备为中心点放大视野
        selectDev(e){
            this.showResult = false;
            this.key = '';
            this.valueData = [];
            this.iconShow = true;
            let position = [e.lon, e.lat]; 
            this.map.setCenter(position); 
            this.map.setZoom(16); 
        }
    }
}
</script>
<style lang="scss">
#container {
    width:100%; height: 580px;
    .amap-marker-label{color: #443535;border: 1px solid #d2d2d2 !important;}
    .amap-copyright{display: none !important;}
}
.location{
    .online{color:#0957ff !important;font-weight: bold;}
    .offline{color: #717171 !important;font-weight: bold;}
    .search-btn{
        position: absolute;top: 2%;right: 2%;z-index: 999;
        .van-icon{font-size: 1.5rem;color: #0957ff;font-weight: bold}
        }
    .search-box{
        position: absolute;top: 0;left: 0;z-index: 999;width: 100%;
        .box-inner{height: 14rem;overflow-y: scroll;}
    }
    .fade-leave-active,.fade-enter-active {
        transition: all .5s ease;
    }
    .fade-enter,.fade-leave-to{
        transform:translate3d(50%, 0, 0);
        opacity: 0;
    }
}
</style>